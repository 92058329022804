import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Itr1 from "./ITR1";
import Aadhar1 from "./Aadhar1";
import Aadhar0 from "./Aadhar0";
import AadharVerify from "./AadharVerify";
import { useNavigate } from "react-router-dom";
import ElKYC from "./ElKYC";

const Kyc = () => {
  const [data, setData] = useState({});
  const [show, setShow] = useState({});
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}/v2/kyc/get-kyc`, {}, { headers })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching KYC data:", error);
      });

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error fetching verification data:", error);
      });
  }, [token]);

  if (show.aadharVerify && show.itrVerify̥) {
    return navigate("/dashboard");
  }

  return (
    <div className="max-w-[1000px] mb-6 p-10 text-black m-auto mt-4">
      <h1 className="font-semibold text-5xl text-white">KYC Verification</h1>
      <div id="glassMorph" className="w-full rounded-lg mt-10">
        <ToastContainer />
        {data.aadharNo === null ? <Aadhar1 /> : <Aadhar0 />}
        {!show.elVerify && <ElKYC />}
        {!show.aadharVerify && (
          <div>
            {data && (
              <div>
                <AadharVerify salaryCheck={data.salarySlipCurrentMonthPath} />
              </div>
            )}
          </div>
        )}
        {!show.itrVerify && <Itr1 />}
      </div>
      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground3"
      ></div>
    </div>
  );
};

export default Kyc;
