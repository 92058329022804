import React, { useState } from "react";
import axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GETCredit = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [creditAmount, setCreditAmount] = useState("");

  const handleChange = (e) => {
    setCreditAmount(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "X-admin-auth": "1234567890",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/getCredit/add-amount`,
        {
          creditAmount: creditAmount,
        },
        {
          headers: headers,
        }
      );

      toast.success("You Got The Credit", { theme: "colored" });
    } catch (error) {
      toast.error(`${error.message}`, { theme: "colored" });
      console.error("Error fetching credit:", error);
    } finally {
    }
  };

  return (
    <div>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded-lg focus:outline-none hover:bg-green-600"
        onClick={() => setIsOpen(true)}
      >
        Get Credit
      </button>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg w-96">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Get Credit</h2>
              <button onClick={() => setIsOpen(false)}>
                <RiCloseLine className="text-gray-500" />
              </button>
            </div>
            <div className="overflow-x-auto mt-4 mb-4">
              <table className="min-w-full bg-white border border-gray-200">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-gray-200">
                      Flat interest charge during the entire tenure of the loan
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">1.8%</td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-gray-200">
                      Convenience Charge
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">2.2%</td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-gray-200">
                      Processing fees
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">2%</td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-gray-200">
                      Data Management Charge
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">2%</td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-gray-200">
                      E-Stamping Charge
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">2%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mb-4">
              <input
                type="number"
                value={creditAmount}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter credit amount"
              />
            </div>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg focus:outline-none hover:bg-blue-600"
            >
              Get Credit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GETCredit;
