import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const MobileNav = ({ ChangeView, closeNav }) => {
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav onClick={handleDropdownToggle} className="">
      <ul className="flex items-center">
        <li className="relative group">
          <div
            className="text-black p-1 flex items-center text-sm  flex-row gap-3"
            onClick={handleDropdownToggle}
          >
            <p>More</p>
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </div>
          <AnimatePresence>
            {isDropdownOpen && (
              <motion.ul
                className="dropdown-menu  bg-white shadow-lg flex-col flex  rounded-md mt-2"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <div
                  onClick={() => {
                    ChangeView(9);
                    closeNav();
                  }}
                  className="px-2 py-2 text-sm  hover:bg-gray-100 cursor-pointer"
                >
                  Contact-Page
                </div>
                <div
                  onClick={() => {
                    ChangeView(5);
                    closeNav();
                  }}
                  className="px-2 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                >
                  Faq
                </div>
                <div
                  onClick={() => {
                    ChangeView(6);
                    closeNav();
                  }}
                  className="px-2 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                >
                  Eligibility
                </div>
                <div
                  onClick={() => {
                    ChangeView(23);
                    closeNav();
                  }}
                  className="px-2 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                >
                  WhyUs
                </div>
                <div
                  onClick={() => {
                    ChangeView(7);
                    closeNav();
                  }}
                  className="px-2 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                >
                  Financial Dictionary
                </div>
              </motion.ul>
            )}
          </AnimatePresence>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNav;
