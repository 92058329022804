import axios from "axios";

const Test1 = () => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "X-admin-auth": "1234567890",
  };

  const handleClick = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/kyc/store-electricity-bill`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {}
  };

  const handleAutoPay = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/upi-autopay/initiate-upi-autopay`,
        {},
        {
          headers,
        }
      );

      console.log("Data for interest>>>>", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-row gap-10">
        <div onClick={handleClick}>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Click
          </button>
        </div>
        <div onClick={handleAutoPay}>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            AutoPay
          </button>
        </div>
      </div>
    </div>
  );
};

export default Test1;
