import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate as useNavigation } from "react-router-dom";

import axios from "axios";
import AdminSalarySlip from "./AdminSalarySlip";
import AdminAmountID from "./AdminExtras/AdminAmountUpdateToID";

import AdminITR from "./AdminExtras/AdminITR";
import AdminOK from "./AdminExtras/AdminOK";
import AdminCibil26as from "./AdminExtras/AdminCibil26as";
import AdminEDPhoto from "./AdminEDPhoto";
import AdminUserLivePhoto from "./AdminExtras/AdminUserLivePhoto";
import AdminTransaction from "./AdminTransaction/AdminTransaction";
import AdminAutoPay from "./AdminExtras/AdminAutoPay";
import AdminEpfo from "./AdminExtras/AdminEpfo";
import AdminPercentage from "./AdminExtras/AdminPercentage";
import AdminImages from "./AdminExtras/AdminImages";
import AdminCourtCase from "./AdminExtras/AdminCourtCase";
import AdminCancelMandate from "./AdminExtras/AdminCancelMandate";

const AdminUserId = () => {
  const { id } = useParams();
  const userId = parseInt(id);
  const navigation = useNavigation();
  const [userDetails, setUserDetails] = useState(null);
  const [kycDetails, setKycDetails] = useState(null);
  const [edDetails, setEdDetails] = useState(null);
  const [verifyAll1, setVerifyAll] = useState([]);
  const [show, setShow] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
      "X-admin-auth": "1234567890",
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  if (show.isAdmin === false) {
    navigation("/");
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const userDetailsResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/userid`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserDetails(userDetailsResponse.data.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const kycDetailsResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/admin-kyc`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setKycDetails(kycDetailsResponse.data.data);
      } catch (error) {
        console.error("Error fetching KYC details:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const edDetailsResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/ed-user`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEdDetails(edDetailsResponse.data.data);
      } catch (error) {
        console.error("Error fetching educational details:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const edDetailsResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/verifyID`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVerifyAll(edDetailsResponse.data.data);
      } catch (error) {
        console.error("Error fetching verifyAllID details:", error);
      }
    };

    fetchData();
  }, [userId]);

  console.log("KYC Data>>>>", kycDetails);

  const verifyAll = {
    "Aadhar Verification": verifyAll1?.aadharVerify,
    "Cibil Verification": verifyAll1?.cibilVerify,
    "ITR Verification": verifyAll1?.itrVerify,
    "Signzy Signatured": verifyAll1?.signzySent,
    "Admin Access": verifyAll1?.isAdmin,
    "User Accepted": verifyAll1?.PaymentAccess,
    "User Declined": verifyAll1?.RejectedProfile,
  };
  return (
    <>
      <div id="wrapper">
        <div className="m-10 flex flex-col xl:flex-row justify-center gap-2">
          <div className="p-5 bg-slate-900 rounded-lg">
            <AdminOK userId={userId} show={verifyAll1} />
          </div>
          <div className="p-5 bg-slate-900 rounded-lg">
            <AdminAmountID userID={userId} />
          </div>
          <div>
            <AdminAutoPay userID={userId} show={verifyAll1} />
          </div>
          <div>
            <AdminCancelMandate userId={userId} />
          </div>
        </div>
        <div className="m-10 flex flex-wrap justify-space gap-2">
          <div className="flex-[2] bg-white rounded-lg shadow-md border-blue-600 border-b-[10px]">
            <div className="p-5 bg-slate-900 rounded-lg">
              <h2 className="font-semibold text-white text-xl">
                User Information
              </h2>
            </div>
            <div className="p-5">
              <div className="flex flex-col xl:flex-row just gap-5">
                <div className="flex flex-col gap-4">
                  <AdminUserLivePhoto userId={userId} />
                  <AdminPercentage show={show} />
                </div>
                <div className="">
                  {userDetails && (
                    <div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Name</div>
                        <p className="">{userDetails.fullName}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Email:</div>
                        <p>{userDetails.email}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Phone Number:</div>{" "}
                        <p>{userDetails.mobileNumber}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Role:</div>{" "}
                        <p>{userDetails.role}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">IP Address:</div>{" "}
                        <p>{userDetails.ip_address || "N/A"}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Device:</div>
                        <p>{userDetails.device || "N/A"}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Gender:</div>{" "}
                        <p>{userDetails.gender}</p>
                      </div>
                      <div className="mb-2 flex gap-2 flex-row">
                        <div className="font-semibold">Date of Birth:</div>{" "}
                        <p>{new Date(userDetails.dob).toLocaleDateString()}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-[1] bg-white rounded-lg shadow-md border-[#ff5c5c] border-b-[10px]">
            <div className="p-5 bg-slate-900 rounded-lg">
              <h2 className="font-semibold text-white text-xl">Status</h2>
            </div>
            <div className="p-5">
              {verifyAll && (
                <div>
                  <div className="flex flex-col gap-4">
                    {Object.entries(verifyAll).map(([key, value]) => (
                      <div key={key} className="flex justify-between">
                        <p>
                          <strong>{key}</strong>
                        </p>
                        <p
                          className={`font-semibold text-2xl ${
                            value ? "text-[#10ac61]" : "text-[#444444]"
                          }`}
                        >
                          {value ? "●" : "●"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="m-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-space gap-2">
          <div className=" bg-white rounded-lg shadow-md border-[#6a329f] border-b-[10px]">
            <div className="p-5 bg-slate-900 rounded-lg">
              <h2 className="font-semibold text-white text-xl">KYC Data</h2>
            </div>
            <div className="p-4">
              {kycDetails && (
                <div className="flex-grow bg-gray-100 p-4 rounded-lg">
                  <p className="mb-2">
                    <strong>User ID:</strong> {kycDetails.userId}
                  </p>
                  <p className="mb-2">
                    <strong>Aadhar No:</strong> {kycDetails.aadharNo || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>PAN Card No:</strong> {kycDetails.panCardNo}
                  </p>
                  <p className="mb-2">
                    <strong>CIBIL Score:</strong>{" "}
                    {kycDetails.cibilScore || "N/A"}
                  </p>
                </div>
              )}
              {kycDetails && (
                <div className="flex-grow bg-gray-100 p-4 rounded-lg">
                  <p className="mb-2">
                    <strong>UAN No:</strong> {kycDetails.uanNo || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>Bank Account No:</strong>{" "}
                    {kycDetails.bankAccountNo || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>IFSC Code:</strong> {kycDetails.ifscCode || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>Voter ID:</strong> {kycDetails.voterId || "N/A"}
                  </p>
                </div>
              )}
              {kycDetails && (
                <div className="flex-grow bg-gray-100 p-4 rounded-lg">
                  <p className="mb-2">
                    <strong>Bank Name:</strong> {kycDetails.bankName || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>Bank Account Data Stored:</strong>{" "}
                    {kycDetails.bankAccountDataStored ? "Yes" : "No"}
                  </p>
                  <p className="mb-2">
                    <strong>Created At:</strong>{" "}
                    {new Date(kycDetails.createdAt).toLocaleDateString()}
                  </p>
                  <p className="mb-2">
                    <strong>Updated At:</strong>{" "}
                    {new Date(kycDetails.updatedAt).toLocaleDateString()}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white shadow-md border-[#e6db46] border-b-[10px] rounded-lg">
            <div className="p-5 bg-gray-900 rounded-t-lg">
              <h2 className="font-semibold text-white text-xl">
                Employment Data
              </h2>
            </div>
            <div className="p-4 ">
              {edDetails && (
                <div>
                  <div className="mb-4 flex flex-col gap-2">
                    <p>
                      <strong>Company Name:</strong>{" "}
                      {edDetails.companyName || "N/A"}
                    </p>
                    <p>
                      <strong>Corporate Email:</strong>{" "}
                      {edDetails.corporateEmail || "N/A"}
                    </p>
                    <p>
                      <strong>Net Salary:</strong>{" "}
                      {edDetails.netSalary || "N/A"}
                    </p>
                    <p>
                      <strong>Designation:</strong>{" "}
                      {edDetails.designation || "N/A"}
                    </p>
                    <p>
                      <strong>UAN No:</strong> {edDetails.uanNo || "N/A"}
                    </p>
                  </div>
                  <div></div>
                </div>
              )}
              {/* <AdminEDPhoto id={edDetails?.idCardPicLivePath} />
              {!edDetails && <p>No Employment Details Available</p>} */}
            </div>
          </div>
        </div>

        <section className="flex justify-around flex-col gap-2 w-full xl:flex-row">
          <div className="p-2">
            <AdminITR userId={userId} />
          </div>
          <div className="p-2">
            <AdminCibil26as userId={userId} />
          </div>
        </section>
        <section className="flex justify-around flex-col gap-2 w-full xl:flex-row">
          <div className="p-2">
            <AdminEpfo userId={userId} />
          </div>
          <div className="p-2">
            <AdminCourtCase userId={userId} />
          </div>
        </section>
        <section>
          <div className="flex justify-center flex-col gap-2 w-full">
            <AdminImages userId={userId} />
          </div>
        </section>
        <section>
          <div>
            <AdminTransaction />
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminUserId;
