import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import admin2 from "../core/images/admin1.gif";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CloudUpload, Done } from "@mui/icons-material";

const AadharVerify = ({ salaryCheck }) => {
  const [file, setFile] = useState("");
  const [adhrOpt, setAdhrOpt] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  console.log("File>>>>", file);

  const SalarySlip = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      if (!file) {
        throw new Error("File need to be added");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/kyc/upload-salary-slip`,
        {
          image: file,
        },
        config
      );

      toast.success("Uploaded Salary-Slip", { theme: "colored" });
      window.location.reload();
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error posting SlarySlip", { theme: "colored" });
    } finally {
      setLoading(false);
    }
  };

  const token = localStorage.getItem("token");
  console.log("TOken>>>>>", token);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type to form data
      Authorization: `Bearer ${token}`, // Include the Bearer token in the headers
    },
  };

  const handleVerify = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/v2/kyc/send-aadhar-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(),
        }
      );

      const responseData = await response.json();
      toast.success("OTP Sent Successfully", { theme: "colored" });

      const timer = setTimeout(() => {
        setShowDiv(true);
      }, 6000);

      console.log("API Response:  ", responseData.data);
    } catch (error) {
      // toast.error("error otp sent", { theme: "colored" });
      console.error("Error posting data:", error);
    }
  };

  const handleOtp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/v2/kyc/verify-aadhar?otp=${adhrOpt}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        window.location.reload();
      }

      toast.success("Aadhar verify Done", { theme: "colored" });
      console.log("OTP Response:  ", responseData);
    } catch (error) {
      toast.error("Incorrect OTP", { theme: "colored" });
      console.error("Error posting data:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleChangeOTP = (e) => {
    setAdhrOpt(e.target.value);
  };

  return (
    <div className="w-[80%] m-auto">
      <div className="flex flex-wrap gap-10 flex-col justify-center items-center lg:flex-row">
        <div className="flex-[2]">
          <center>
            <img src={admin2} width={250} height={200} alt="" />
          </center>
        </div>
        <div className="flex-[3]">
          {salaryCheck ? (
            <></>
          ) : (
            <>
              <p className="font-semibold font-lg text-white mt-5">
                Upload Your Salary Slip
              </p>
              <br />
              <input
                id="outlined-basic"
                type="file"
                className="py-2 px-4 w-full bg-gray-950 focus:bg-gray-900 text-sm border-gray-950 border-[1px] text-white rounded-sm"
                onChange={handleFileChange}
                variant="outlined"
                accept="image/png, image/jpeg"
              />
              <span className="text-xs text-white">
                Supported File Types .png .jpg
              </span>
              <br />

              <button
                className="w-full py-2 text-sm px-4 mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
                variant="contained"
                onClick={SalarySlip}
              >
                {loading ? "Uploading..." : "Upload"}
                <CloudUpload className="pl-2" />
              </button>
            </>
          )}

          <p className="font-semibold font-lg mt-5 text-white">
            Verify Your Aadhar Card
          </p>
          <span className="text-xs text-white">
            Enter 6 Digit OTP, Check Your SMS!
          </span>
          <br />
          <input
            type="text"
            placeholder="______"
            className="py-2 px-4 w-full bg-gray-950 focus:bg-gray-900 text-sm border-gray-950 border-[1px] text-white rounded-sm tracking-widest"
            value={adhrOpt}
            maxLength={6}
            max={6}
            onChange={handleChangeOTP}
            name="adhrOpt"
          />
          {showDiv && (
            <div onClick={handleVerify}>
              <p className="cursor-pointer text-blue-600">resend otp</p>
            </div>
          )}
          <div className="flex flex-wrap flex-row gap-5">
            <div className="flex-[1]">
              <button
                className="w-full text-sm py-2 px-4 mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
                onClick={handleVerify}
              >
                Verify OTP <ArrowRightAltIcon />
              </button>
            </div>
            <div className="flex-[1]">
              <button
                className="w-full text-sm py-2 px-4 mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
                onClick={handleOtp}
              >
                Finish <Done />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AadharVerify;
